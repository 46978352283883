.questionnaire-management::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4e3e34b;
  z-index: -1;
}

.questionnaire-management {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: calc(100% - 250px); /* Adjust based on your navbar width */
  width: 100%;
  padding: 30px;
  background-color: inherit;
  min-height: 100vh;
  box-sizing: border-box;
  text-align: right;
}

.answers-section > h3 {
  margin-bottom: 5px;
}

.button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.button:hover {
  background-color: #2980b9;
}

.question {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.28);
}

.answers-table {
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  border: 1px solid #a9a9a9;
  width: 100%;
  table-layout: fixed;
}

.answers-table tr {
  width: 100%;
}

.answers-table td {
  border: 1px outset #a9a9a9;
  text-align: right;
  padding: 0.5rem;
  word-wrap: break-word;
}

.answers-table td:nth-child(3) {
  text-align: center;
}

.edit-answer-row {
  display: table-row;
}

.edit-answer-row input, .new-answer-row input {
  width: 100%;
  box-sizing: border-box;
}

/* not mui input */
.edit-answer-row input:not(.MuiInputBase-input), .new-answer-row input:not(.MuiInputBase-input) {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;

}

/*when typing in input, not mui input */
.edit-answer-row input:not(.MuiInputBase-input):focus, .new-answer-row input:not(.MuiInputBase-input):focus {
  outline: none;
  border: 2px solid #1976d2;
}
.cancel-answer {
  background-color: #dc3545;
  color: black;
  flex-direction: flex-end;
}

.ReactModal__Content:not(.EditScoreDescModal) {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease;
  max-width: 50vw;
  max-height: 95vh;
  align-self: center;
  justify-self: center;
}

.answers-section {
  max-height: 100%;
}

.answers-table-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.answers-table-container {
  width: 60vw;
}

.edit-answer-row input {
  justify-self: center;
  align-self: center;
}

.required-checkbox {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  background-color: #f8f9fa;
  display: inline-block;
  margin-right: 10px;
  text-justify: center;
  font-weight: 600;
}

.required-checkbox input[type="checkbox"] {
  margin-left: 5px;
}

.question-label {
  display: flex;
  gap: 8px;
}

.question:hover {
  transform: translateY(-2px);
  background-color: #f1f8ff;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-header span {
  font-weight: 600;
  color: #343a40;
  flex-grow: 1;
}

.add-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add-checkbox h1 {
  order: 1;
}

.required-checkbox-add {
  margin-left: 20px;
  margin-top: 30px;
}

body > div.ReactModalPortal > div > div > div > div > label {
  margin-left: 5px;
}

body > div.ReactModalPortal > div > div > div > div > label > input[type=checkbox] {
  margin-left: 5px;
}

#newQuestionText {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30vw;
}

.question-header button:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.questionnaire-management button:not(.add-question-button):not(.edit-score-desc-button) {
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s ease;
  background-color: transparent !important;
}

.questionnaire-management button:hover {
  background-color: #f8f9fa;
}

.add-question-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #28a745;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.add-question-button:hover {
  background-color: #218838 !important;
}

.edit-score-desc-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #2980b9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.edit-score-desc-button:hover {
  background-color: #1c6da8 !important;
}

.add-answer-button {
  color: white;
  background-color: #007bff;
  font-weight: 600;
  margin: 5px;
}

.add-answer-button:hover {
  background-color: #0056b3;
}

.editButton, .trashButton {
  background-color: transparent;
  color: white;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
}

.editButton:hover, .trashButton:hover {
  background-color: #f8f9fa;
  color: #343a40;
}

.save-button {
  background-color: #28a745 !important;
  font-weight: 600;
  color: white;
  border: none;
  margin: 0 auto;
  display: block;
  padding: 10px;
  margin-top: 20px;
}

.save-button:hover {
  background-color: #218838 !important;
}

.ReactModal__Overlay:not(.ESDOverlay) {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionnaire-management .ReactModal__Content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease;
  max-width: 50vw;
  align-self: center;
  justify-self: center;
}

.Modal input {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30vw;
}

.question-input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 30vw;
}

.question-input:focus {
  outline: none;
  border-color: #3498db;
}

.save-answer {
  background-color: #28a745;
  color: white;
  font-weight: 600;
}

.save-answer:hover {
  background-color: #218838;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent !important;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #000;
}

.add-question-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.submit-add-question {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  margin: 0 auto;
  display: block;
  transition: background-color 0.2s ease;
}

.submit-add-question:hover {
  background-color: #218838;
}

.delete-confirm-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.delete-confirm-content p {
  margin: auto;
  text-align: center;
}

.delete-confirm-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.description-actions {
  display: flex;
  flex-direction: row;
}

.description-actions .description-textarea {
  width: 100%;
  margin-left: 10px;
  border-radius: 4px;
  padding: 10px;
  scrollbar-width: thin;
}

#root > div > div > div > div.admin-dashboard-content > div > div.description-section > div.description-actions > button {
  background-color: #28a745 !important;
  font-weight: 600;
  color: white;
  border: none;
  margin: 0 auto;
  display: block;
  padding: 5px 20px;
  font-size: 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#root > div > div > div > div.admin-dashboard-content > div > div.description-section > div.description-actions > button:hover {
  background-color: #218838 !important;
}

div.questionnaire-management > h1 {
  text-align: center;
}
