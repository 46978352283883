/* Container for the whole component */
.admin-post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: 25em;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    align-self: stretch;
    overflow: hidden; /* Hide overflow content */
}

.admin-post-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.admin-post-container .detailed-post-container {
    flex-grow: 1;
    overflow-y: auto; /* Allow scrolling for content */
    width: 100%;
    padding: 10px;
}

.admin-post-container .detailed-post-container:hover {
    background-color: #f0f0f0;
    transform: none;
    box-shadow: none;
}

/* Admin bar styling */
.admin-post-container .admin-bar {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px 0;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}

/* Rest of the CSS remains the same... */
.admin-post-container .admin-button {
	background-color: #a0b2c5;
	color: white;
	border: none;
	padding: 7px 16px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
	margin: 0 5px; /* Add margin between buttons */
	font-size: 1em;
	font-weight: 450;
}

.admin-post-container .admin-button:hover {
	background-color: #666262;
}

.admin-post-container .admin-button:disabled {
	background-color: #a0a0a0;
	cursor: not-allowed;
}

.admin-post-container .admin-button.delete-button {
	background-color: #dc3545;
}

.admin-post-container .admin-button.delete-button:hover {
	background-color: #c82333;
}

/* Specific button styling for publish/unpublish */
.admin-post-container .publish-button {
	background-color: #59a66b;
}

.admin-post-container .publish-button:hover {
	background-color: #218838;
}

.admin-post-container .post-type-bubble {
	display: none; /* don't need this bubble by default */
	position: absolute; /* Position the bubble */
	top: 8px; /* Adjust as necessary */
	right: 8px; /* Adjust as necessary */
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 10px;
	margin-bottom: 30px;
}

.admin-post-container .post-type-bubble.posttype {
	background-color: #413b44; /* Gray color for "post" */
}

.admin-post-container .post-type-bubble.convention {
	background-color: #a1564d; /* Green color for "convention" */
}

/* Responsive design */
@media (max-width: 768px) {
	.admin-bar {
		flex-direction: column;
		align-items: center;
		position: static; /* Adjust position for smaller screens */
	}

	.admin-button {
		margin-bottom: 10px;
		width: 100%;
	}
}
