/* src/screens/AdminDashboard/AdminDashboard.css */
.admin-dashboard {
  display: flex;
}

.admin-dashboard-content {
  display: flex;
  padding: 0px;
  margin: 0px;
  padding-right: var(--admin-sidebar-width); /* Leave space for the sidebar */
  width: 100%;
  box-sizing: border-box; /* Include padding in total width */
  background-color: #f5f5f5; /* Light background color for better readability */
  justify-content: center;
  min-height: 100vh;
}


@media (max-width: 768px){
  .admin-dashboard-content {
    padding-right: 0px;
  }
}