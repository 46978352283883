.admin-user-history-container {
  width: 90%;
  min-height: 100vh;
  box-sizing: border-box;
}

.button, .export-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px; /* Added margin space below the button */
  text-decoration: none; /* Remove underline for CSV link */
  display: inline-block; /* Ensure it displays like a button */
}

.button:hover, .export-button:hover {
  background-color: #2980b9;
}

.spacer {
  height: 20px; /* Add space between the button and the question list */
}

.history-grid {
  display: grid;
  /* Adjust grid columns based on screen size, maximum 3 columns */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px; /* Gap between blocks */
}

.history-entry {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Added for positioning icons */
}

.history-entry h2 {
  margin-bottom: 10px;
}

.icon {
  font-size: 1.5em;
  color: #a04598;
  cursor: pointer;
}

.icon:hover {
  color: #ac1c74;
}

.actions {
  display: flex; /* Ensure buttons are aligned horizontally */
  gap: 10px; /* Space between buttons */
}

.actions .icon {
  cursor: pointer;
  margin-left: 10px;
}

.actions .icon:hover {
  color: #2980b9;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Optional: add a dark background to the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 40px; /* Increased padding for spaciousness */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Adjust width as needed */
  width: 90%; /* Ensure it doesn't exceed the viewport width */
  margin: auto;
  overflow: hidden; /* Ensure content stays within modal */
}

.modal-content h2 {
  margin-top: 0;
  text-align: center;
}

.response-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.response-item {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.response-item p {
  margin: 5px 0;
}

.modal-close-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.modal-close-button:hover {
  background-color: #2980b9;
}

.filter-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}

.filter-container label {
  margin: 5px 0;
  white-space: nowrap; /* Prevents the text from breaking into multiple lines */
}

.statistics-container {
  width: 100%;
  margin-top: 30px;
}

.question-stats-box {
  background-color: #fff;
  padding: 20px; /* Increased padding */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.question-stats-box h3 {
  margin-top: 0;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-chart-container {
  width: 400px; /* Adjusted width */
  height: 200px; /* Adjusted height */
  margin-left: auto; 
  margin-right: auto; /* Center the pie chart */
}

.date-label {
  white-space: nowrap;
}

.question-stats-box ul {
  list-style: none;
  padding: 0;
}

.question-stats-box ul li {
  margin-bottom: 10px;
}

.no-history {
  font-size: 1.5em;
  color: #888;
  margin-top: 20px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.score-ranges {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.score-ranges p {
  margin: 5px 0;
  font-size: 1.2em;
}

/* Media Queries */
@media (max-width: 1200px) {
  .history-grid {
    grid-template-columns: 1fr 1fr; /* Two columns for larger screens */
  }
}

@media (max-width: 992px) {
  .admin-user-history-container {
    margin-right: 0; /* Remove right margin for smaller screens */
    padding: 15px;
  }

  .history-grid {
    grid-template-columns: 1fr; /* Single column for medium screens */
  }

  .history-entry {
    padding: 10px;
  }

  .button, .export-button {
    padding: 8px 16px;
  }

  .modal-content {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .admin-user-history-container {
    padding: 10px;
  }

  .history-entry {
    padding: 8px;
  }

  .button, .export-button {
    padding: 6px 12px;
  }

  .modal-content {
    padding: 15px;
  }

  .pie-chart-container {
    width: 300px;
    height: 500px;
  }
}

@media (max-width: 576px) {
  .admin-user-history-container {
    padding: 5px;
  }

  .history-entry {
    padding: 5px;
  }

  .button, .export-button {
    padding: 4px 8px;
  }

  .modal-content {
    padding: 10px;
  }

  .pie-chart-container {
    width: 200px;
    height: 800px;
  }
}
