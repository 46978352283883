/* src/screens/Helpscore/CalculateHelpScore.css */

.score-container {
    text-align:center;
    padding: 25px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 30%;
    margin: 4% auto;
    background-color:#f3f3f3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.unique-background {
    background-color: #ffffff;
}


.score-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.score-container p {
    font-size: auto;
    margin-bottom: 20px;
}

.score-container button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #a20067;
    border: 1px solid #ac1c74;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 4%;
    margin-right: 2%;
    margin-left: 2%;
    font-weight: 550;
}


.score-container button:hover {
    background-color: 
    #6c4a8c;
}

.error-message {
    color: #790000;
    font-weight: bold;
}

/* Modal styles */
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: white;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    z-index: 1001;
}

.Modal h2 {
    margin-bottom: 20px;
}

.Modal input {
    display: block;
    margin: 10px 0;
    padding: 8px;
    width: calc(100% - 16px);
    border: 1px solid #ccc;
    border-radius: 5px;
}


.score-result {
    font-weight: bold;
    color: #a20067;
    font-size: 1.5em;
}

.user-score
{
    font-size: 1.5em;
}

.button-group {
    display: flex;
    justify-content: center;
    font-weight: normal;

}

.button-group button {
    font: -apple-system, BlinkMacSystemFont;
    background-color: #a04598;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin: 10px 10px;
    font-weight: 550;
}

.button-group button:hover {
    background-color: #ac1c74;
}