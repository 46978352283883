.about-info {
    border-radius: 8px;
    width: 100%;
    text-align: center;
}

#about > h3 > p ,#about > h2{
    color: #333333dc;

}

.about-info-content {
    margin-top:0;
    margin-bottom: 20px;
}

#about > h2 {
	margin-bottom: 0.3em;
}

#about > div{
    font-size: 1.2em;
}

#about > div br {
    margin-bottom: 40px;
    margin-top: 40px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    margin: auto;
    overflow: hidden;
    text-align: center; /* Center text content */
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center form elements vertically */
}

.modal-content form .modal-field {
    width: 100%; /* Ensure full width for form fields */
    text-align: center;
}

.modal-content form label {
    display: block;
    text-align: center; /* Center label text */
    margin-bottom: 5px;
    width: 100%; /* Ensure full width for labels */
}

.modal-content form textarea {
    width: 100%; /* Full width */
    height: 150px; /* Increase height */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    box-sizing: border-box; /* Ensure padding is included in width */
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%; /* Ensure full width for actions */
}

.modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-actions button:hover {
    background-color: #0056b3;
}

.modal-actions button:nth-child(2) {
    background-color: #ccc;
}

.modal-actions button:nth-child(2):hover {
    background-color: #aaa;
}
