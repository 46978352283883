.login-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 90%;
    background-color: #00000090;
    display: grid;
    direction: rtl;
    /* Right-to-left direction */
}

.login-popup-container {
    place-self: center;
    width: max(23vw, 330px);
    color: #80808080;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 30px;
    border-radius: 8px;
    font-size: 14px;
    animation: fadeIn 0.5s;
}

.login-popup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.login-popup-title img {
    width: 16px;
    cursor: pointer;
}

.login-popup-input {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.login-popup-input input {
    outline: none;
    border: 1px solid #c9c9c9;
    padding: 10px;
    border-radius: 4px;
}

.email-suggestions {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    background: #f5f5f5;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.email-suggestions li {
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: black;
}

.email-suggestions li:hover {
    background-color: #f5f5f5;
}

.login-popup-container button {
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: white;
    background-color: #a04598;
    font-size: 15px;
    cursor: pointer;
}

.login-popup-container button:hover {
    background-color: #a63a7f;
}

.login-popup-condition {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: -15px;
}

.login-popup-condition input {
    margin-top: 0px;
}

.login-popup p span {
    color: #a04598;
    font-weight: 500;
    cursor: pointer;
}

.reset-password-button {
    background: #a04598;
    color: white;
    border: none;
    padding: 10px;
}

.reset-password-link {
    background: none;
    color: gray;
    text-decoration: underline;
    border: none;
    padding: 0;
    font-size: 15px;
    cursor: pointer;
    text-align: right;
    /* Align to the start of the line */
}

.reset-password-link:hover {
    color: #555;
}

.switcher {
    font-size: 1.1em;
}

/* class for switcher that's not span */
.switcher:not(span) {
    /* CSS rules for the switcher that's not a span */
    color: #8b8282;
}

.modal-password {
    place-self: center;
    width: max(23vw, 330px);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 30px;
    border-radius: 8px;
    font-size: 14px;
}

.modal-password-header h2 {
    font-size: 1.2em;
    /* Make the title smaller */
    text-align: center;
    /* Center the title */
    margin: 0 auto;
    /* Center the title */
}

.modal-password-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    /* border-bottom: 1px solid #c9c9c9; */
}

.modal-password-header img {
    width: 16px;
    /* align in top left corner */
    margin-top: -1em;
    cursor: pointer;
}

.modal-password-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Use 100% width to ensure proper alignment */
    margin-top: -1em;
}

.modal-password-body label {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-left: 1em;

}

.modal-password-body label span {
    align-self:flex-start;
    margin-top: 0.3em;
    font-size: 1em;
    text-align: right;
}

.modal-password-body input {
    text-align: left;
    /* Align text to the center */
    justify-self: center;
    align-self: center;
    height: 3vh;
    width: 100%;
    margin-bottom: 15px;
}

.reset-password-modal-button {
    background: #a04598;
    color: white;
    border: none;
    padding: 5px;
    width: 70%;
    margin: 0 auto;
    font-size: 1em;
    font-weight: 500;
    display: block;
    text-align: center;
    cursor: pointer;
    align-self: center; /* Ensure the button centers itself */
}

.reset-password-modal-button:hover {
    background-color: #a63a7f;
}
