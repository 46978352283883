.admin-miscellaneous-container {
	width: 100%;
	align-items: center;
	margin-left: auto;
	max-width: auto;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	background-color: #f8f9fa; /* Light background color for the entire page */
	padding: var(--padding-general);
}

.admin-miscellaneous-container h1 {
	margin-bottom: 20px;
	text-align: center;
	color: #333; /* Dark text color for better readability */
}

.admin-miscellaneous-container .miscellaneous-content {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-auto-rows: minmax(auto, 1fr);
	grid-gap: 20px;
	justify-content: stretch;
	align-items: flex-start;
	width: 100%;
	/* max-width: 1200px;  */
	padding: 20px;
}

.admin-miscellaneous-container .section {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	box-sizing: border-box;
	background: #fff; /* Background color for sections */
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition: transform 0.2s; /* Smooth transition for hover effect */
	position: relative; /* For the tooltip positioning */
}

.admin-miscellaneous-container .section:hover {
	transform: translateY(-5px); /* Slight lift on hover */
}

.admin-miscellaneous-container .section h2 {
	text-align: center;
	margin-bottom: 10px;
	color: #555; /* Slightly lighter text color for subheadings */
}

.admin-miscellaneous-container .section .details {
	display: none; /* Hide the details initially */
}

.admin-miscellaneous-container .section:hover .details {
	display: block; /* Show the details on hover */
	position: absolute;
	top: 100%; /* Position below the section */
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1;
}

.admin-miscellaneous-container button.update-button,
.update-button,
.admin-miscellaneous-container .update-button {
	background-color: #7b4b8e;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: block;
	margin: 10px 0; /* Adjust margin to be under h2 */
	padding: 10px 20px;
	width: 100% !important;
}

.admin-miscellaneous-container button.update-button:hover {
	background-color: #634585;
}

.modal-actions button {
	background-color: #28a74b;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	/* one item on each corner */
	justify-content: space-between;
}

.modal-actions button.save-changes {
	background-color: #28a74b;
	color: white;
	font-weight: 400;
}

.modal-actions button.cancel-changes {
	background-color: #555;
	color: white;
	font-weight: 400;
}

.modal-field input {
	flex: 1;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: fit-content;
}

.new-field {
	display: flex;
	flex-direction: column;
}

.new-field .wide-input {
	flex: 1;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-bottom: 10px;
}

@media screen and (max-width: 300px) {
	.admin-miscellaneous-container .miscellaneous-content {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
}
