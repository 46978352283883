/* src/components/BankInfo/BankInfo.css */

.bank-info {

    border-radius: 8px;
    width: 100%;
    text-align: right;
  }
  

  
  .bank-info-content p {
    margin: 0 0 10px;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 90%;
    margin: auto;
    overflow: hidden;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content form div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .modal-content form label {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .modal-content form input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: fit-content;
  }
  
  .add-field-button {
    text-align: center;
  }
  
  .delete-icon {
    color: #e74c3c;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .delete-icon:hover {
    color: #c0392b;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-actions button:hover {
    background-color: #0056b3;
  }
  
  .modal-actions button:nth-child(2) {
    background-color: #ccc;
  }
  
  .modal-actions button:nth-child(2):hover {
    background-color: #aaa;
  }
  
  
  .new-field h3 {
    margin-bottom: 10px;
  }
  
  .new-field input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 10px;
    width: calc(50% - 10px);
  }
  
  .new-field button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    align-self: flex-end;
  }
  
  .new-field button:hover {
    background-color: #2980b9;
  }
  
  .bankinfo-table {

    text-align: right;
  }

  .bankinfo-table input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;

  }