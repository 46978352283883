.general-posts-presentor {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	--post-grid-width: 350px;
	--post-grid-gap: 10px;
	--post-grid-max-width: 400px;
}

.general-posts-presentor .posts-pagination-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	width: 100%;
}

.posts-pagination-buttons .pagination-button {
	background-color: #218838;
	border: none;
	color: white;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s, transform 0.3s;
	font-weight: 500;
	margin-bottom: 20px;
}

.admin-dashboard-posts-navbar-menu .active-tab {
	background-color: #575757;
	border-radius: 5px;
}

.posts-pagination-buttons .pagination-button:hover {
	background-color: #b370cc;
	transform: scale(1.05);
}
.posts-pagination-buttons .pagination-button:disabled {
	background-color: #575757;
	transform: scale(1.05);
}
.posts-pagination-buttons .pagination-button:focus {
	transform: none;
	outline: none;
}

.general-posts-presentor .posts-presentor-search-container {
	margin: 0 auto;
	width: 70%;
	margin-top: 0;
	margin-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.posts-presentor-search-container .posts-presentor-search-input {
	padding: 8px;
	align-self: stretch;
	border: 2px solid #a8a8a8;
	font-size: 20px;
	border-radius: 4px;
	margin-top: 20px;
	transition: box-shadow 0.3s;
}

.general-posts-presentor .presentor-posts-container {
	display: grid;
	width: 100%;
	justify-content: center;
	align-items: center;
	grid-template-columns: repeat(auto-fit, var(--post-grid-width));
	grid-auto-rows: minmax(auto, 1fr);
	grid-gap: var(--post-grid-gap);
}

@media (max-width: 768px) {
	.general-posts-presentor .presentor-posts-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.general-posts-presentor .posts-presentor-search-container {
		width: 100%;
	}
}
