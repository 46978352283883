/* src/screens/Signup/Signup.css */
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f5f5f5;  /* Only an idiot would use gradients */
  /* background: linear-gradient(135deg, #f5f5f5, #e0e0e0); */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.signup-form {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 350px;
  max-width: 90%;
  transition: all 0.3s ease;
}

.signup-form:hover {
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

.signup-form h2 {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
  text-align: center;
}

.signup-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.signup-form input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.signup-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-form button:hover {
  background-color: #0056b3;
}

.signup-form p {
  color: red;
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .signup-form {
    width: 90%;
    padding: 1.5rem;
  }

  .signup-form h2 {
    font-size: 1.5rem;
  }
}
