.menu-bar-select-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	padding: 0px;
	left: 1;
}


.text-editor-menu-bar .menu-bar-select,
.text-editor-menu-bar .menu-bar-option {
	display: inline-block;
	border-radius: 0cqh;
	background-color: rgb(255, 255, 255);
	color: black;
	padding: 0px;
	margin: 0px;
}

.text-editor-menu-bar label {
	margin: 0px 10px;
	font-size: 16px;
	font-weight: bold;
}

.menu-bar-select-container input[type='text'] {
	width: 100%;
	padding: 8px;
	margin: 10px 0;
	display: inline-block;
	width: fit-content;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	color: black; /* Ensuring text color is black */
	background-color: white; /* Ensuring background is white */
	font-size: 16px; /* Reasonable font size */
}


