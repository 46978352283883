/* Base styles for the post details container */

.post-details-external-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	padding: var(--padding-general);
}

.post-details-external-container .post-details-container {
	display: flex;
	flex-direction: column;
	width: auto;
	align-self: stretch;
	background-color: #ffffff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	justify-content: right;
	align-items: flex-start;
	padding: 20px;
}

.post-details-container .post-details-elements {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: auto;
	align-self: stretch;
}

/* Styles for the back button */
.post-details-container .back-button {
	background: black;
	border: none;
	cursor: pointer;
	margin-right: 10px;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.post-details-container .post-details-header {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.post-details-container .back-button svg {
	width: 24px;
	height: 24px;
	transition: transform 0.3s ease;
}

.post-details-container .back-button:hover svg {
	transform: scale(1.1);
}

/* Style for the headings within the post details */
.post-details-container .post-details-header h1 {
	color: #333;
	text-align: right;
	margin-bottom: 20px;
}

/* Styling for the paragraph elements */
.post-details-container .post-details-header p {
	color: #666;
	line-height: 1.6;
	font-size: 16px;
	margin-top: 10px;
	text-align: right;
}

/* Specific style for the date to make it less prominent */
.post-details-container .post-details-header p strong {
	color: #333;
	margin-right: 5px;
}

/* Styles for the links to make them stand out */
.post-details-container .post-details-header a {
	display: inline-block;
	background-color: #007bff;
	color: #ffffff;
	padding: 10px 20px;
	margin-top: 20px;
	border-radius: 5px;
	text-decoration: none;
	text-align: center;
	transition: background-color 0.3s;
}

/* Hover effect for links */
.post-details-container .post-details-header a:hover {
	background-color: #0056b3;
}

/* Styling for the narrow content box */
.post-details .narrow-content {
	width: 60%; /* Further reduce width for non-PDF content */
}

@media (max-width: 768px) {
	.post-details-external-container {
		width: auto;
	}
	.post-details-external-container .post-details-container {
		width: auto;
		align-self: stretch;
		padding: 4vw;
	}
}
