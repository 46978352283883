.posts-section {
  background: #ffffff;
  position: relative;
  min-height: fit-content;
}

.posts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.posts-section h2 {
  margin: 0;
}

.posts-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content:  */
}

.view-all-button-container {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 20px;
  justify-content: left;
  /* bottom: 1em; 
  left: 1em;  */
}

.view-all-button {
  background: #a04598;
  color: white;
  border: none;
  padding: 0.6em 1em;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.view-all-button:hover {
  background: #ac1c74;
}

/* Media query for phone responsive design */
@media (max-width: 768px) {
  .posts-section {
    min-height: 500px;
  }

  .posts-container {
    justify-content: center;
  }

  .view-all-button-container {
    position: static;
    text-align: center;
    margin-top: 1em;
  }
}
