/* src/components/NewsletterLink/NewsletterLinkAdmin.css */

.newsletter-link {
	border-radius: 8px;
	width: 100%;
	text-align: center;
	box-sizing: border-box;
}

.newsletter-link-content p {
	margin: 0 0 10px;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	background: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	max-width: 600px;
	width: 90%;
	margin: auto;
	overflow: hidden;
}

.modal-content h2 {
	margin-top: 0;
}

.modal-content form {
	display: flex;
	flex-direction: column;
}

.modal-content form .modal-field {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	text-align: left;
}

.modal-content form label {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.modal-content form input {
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 5px;
}

.modal-actions {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.modal-actions button {
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.modal-actions button:hover {
	background-color: #0056b3;
}

.modal-actions button:nth-child(2) {
	background-color: #ccc;
}

.modal-actions button:nth-child(2):hover {
	background-color: #aaa;
}
