.edit-post-page-external-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: var(--padding-general);
}

.edit-post-page {
	display: flex;
	flex-direction: column;
	background: #fff;
	border: 1px solid #dee2e6;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	align-items: center;
	padding: 10px;
	align-self: stretch;
	justify-content: stretch;
}

.edit-post-page .edit-post-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: right;
	width: 100%;
}

.edit-post-header .editor-header-input {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-self: auto;
	width: 75%;
}
/* General styles remain unchanged */
.edit-post-page .edit-post-header select,
.edit-post-page .edit-post-header option,
.edit-post-page .edit-post-header input[type='text'] {
	/*type text assures that we only take text inputs from the current page, instead of all inputs on the website*/
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: white;
	color: black;
	padding: 0.5rem 1rem;
	margin: 0.5rem 0;
	font-size: 16px;
	flex: 1; /* Make the input take up remaining space */
	/* max-width: 30%; */
}

.edit-post-page .edit-post-header .text-area {
	padding: 10px; /* Some padding */
	border: 1px solid #ccc; /* Border */
	border-radius: 4px; /* Rounded corners */
	font-size: 16px; /* Font size */
	line-height: 1.5; /* Line height */
	width: 100%;
	resize: none;
}

.edit-post-page .edit-post-header input:focus,
.edit-post-page .edit-post-header select:focus,
.edit-post-page .edit-post-header option:focus {
	outline: none;
	border-color: #007bff;
	box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.edit-post-page .edit-post-header h1,
.edit-post-page .edit-post-header h2,
.edit-post-page .edit-post-header h3 {
	padding: 0 20px;
	color: #333;
	text-align: center;
}

.edit-post-page .title-content {
	text-align: right;
}

.edit-post-page .post-contents {
	width: 100%;
	margin-top: 20px;
	padding: 20px;
	border: 1px solid #dee2e6;
	border-radius: 8px;
	background: #fff;
}

.edit-post-page .edit-post-header button,
.edit-post-page .buttons-container button {
	border: none;
	padding: 10px 15px;
	border-radius: 5px;
	cursor: pointer;
	margin: 0px;
	font-size: 16px;
	font-weight: 550;
}

.edit-post-page .edit-post-header button:disabled,
.edit-post-page .buttons-container button:disabled {
	background-color: var(--button-primary-color-disabled);
	cursor: not-allowed;
}

#root > div > div > div > div.admin-dashboard-content > div > div.buttons-container {
	font-weight: 550;
}

.edit-post-page .save-post-button {
	background-color: forestgreen;
}

.edit-post-page .save-post-button:hover {
	background-color: #218838;
}

/* Specific button styles */
.edit-post-page .delete-button {
	background-color: #dc3545;
}

.edit-post-page .delete-button:hover {
	background-color: #c82333;
}

.edit-post-page .publish-button {
	background-color: forestgreen;
	color: white;
}

.edit-post-page .publish-button:hover {
	background-color: #218838;
	color: white;
}

.edit-post-page .publish-button:disabled {
	background-color: #218838;
}

.edit-post-page .unpublish-button {
	background-color: #80787c;
}

.edit-post-page .unpublish-button:hover {
	background-color: #666262;
}

.edit-post-page .back-button-container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.edit-post-page .back-button {
	background-color: #333;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s, transform 0.3s;
	margin: 10px;
	font-size: 16px;
}

.edit-post-page .input-label {
	display: flex;
	width: fit-content;
	margin: 0 10px;
	text-align: right;
	margin-right: 10px;
	font-weight: bold;
	flex-wrap: nowrap;
}

.edit-post-page .buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
	align-items: center;
	width: 100%;
	font-weight: 550;
	border-radius: 5px;
	/* bottom of modal: */
	position: relative;
	bottom: 0;
}

@media (max-width: 768px) {
	.flex-row {
		flex-direction: column;
		align-items: flex-start;
	}

	.input-label {
		text-align: left;
		margin-bottom: 5px;
	}

	.edit-post-header .editor-header-input {
		width: auto;
		align-self: stretch;
	}
}
