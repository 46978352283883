/* Container for the whole component */
.admin-dashboard-posts-container {
	width: 100%;
	margin: 0;
	border: none;
}
/* Header section */
.admin-dashboard-posts-container .admin-dashboard-posts-navbar-container {
	background: #a04598;
	color: white;
	padding: 1em 0;
	position: sticky;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	list-style: none;
}

.admin-dashboard-posts-navbar-container .admin-dashboard-posts-navbar-menu {
	list-style: none;
	display: flex;
	margin: 0px;
	padding: 0px;
}
.admin-dashboard-posts-navbar-container .admin-dashboard-posts-navbar-menu li {
	margin: 1em 2em;
}

.admin-dashboard-posts-navbar-container .admin-dashboard-posts-navbar-menu li button {
	background-color: transparent;
	color: #fff;
	border: none;
	padding: 0.5em 1em;
	cursor: pointer;
	font-size: 20px;
	transition: background-color 0.3s;
}

.admin-dashboard-posts-navbar-container .admin-dashboard-posts-navbar-menu li button:hover {
	background-color: #575757;
	border-radius: 5px;
}


.admin-dashboard-posts-container .add-post-button-container {
	display: flex;
	flex-direction: column;
	width: 90%;
	justify-content: center;
	align-items: right;
}

/* Button styles */
.add-post-button-container .add-post-button {
	background-color: var(--button-primary-color);
	width: fit-content;
	margin-top: 20px;
	border: none;
	padding: 10px 20px;
	margin-right: 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 20px;
	transition: background-color 0.3s;
}


.add-post-button-container .add-post-button:hover {
	background-color: var(--button-primary-color-hover);
	background-color: #218838;
}

.add-post-button-container .add-post-button:disabled {
	cursor: not-allowed;
}

/* Content section */
.add-post-button-container .posts-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

/* Loading and error styles */
.admin-dashboard-posts-container .loading,
.admin-dashboard-posts-container .error {
	font-size: 1.5em;
	color: #333;
	margin-top: 20px;
	text-align: center;
}

.admin-dashboard-posts-container div.detailed-post-container {
    border-radius: 0;
    width: 90%;
    align-self: center;
}

.admin-dashboard-posts-container div.detailed-post-container:hover {
	background-color: #f0f0f0;
	transform: none;
	box-shadow: none;
}

@media (max-width: 768px) {
	.admin-dashboard-posts-container .add-post-button {
		margin-right: 70px;
	}
}
