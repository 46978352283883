.element-editor-container {
	width: 100%;
	border: #333 solid 1px;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	margin: 10px 0px;
}

.element-editor-container .draggable-post-element {
	padding: 0px;
	margin: 0;
	width: 100%;
	background-color: #fff;
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
}

.element-editor-container .drag-handle {
	cursor: grab;
	padding: 4px;
}

.element-editor-container .drag-handle:hover {
	background-color: #f0f0f0b4;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.element-editor-container .element-comp-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.element-editor-container .element-editor-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0px;
	padding: 10px;
	border: #bcbcbc solid 1px;
	background-color: #c2c2c2;
}

.element-editor-container .element-editor-header button,
.element-editor-container .element-editor-header select {
	margin: 0px 10px;
}
.element-editor-container .element-editor-header input {
	width: fit-content;
}

.element-editor-container .element-editor-buttons button{
	font-weight: bold;
}

.element-editor-buttons button:hover{
	transition: background-color 1s, transform 1s;
	background-color: #ececec;
	color: var(--button-primary-color);
	font-weight: bold;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}