/* src/screens/AdminDashboard/Users.css */
.users {
  padding: 20px;
  width: 70%; /* Adjust content width to 70% */
  box-sizing: border-box; /* Include padding in total width */
  background-color: #f4f7f6;
}

.users h1 {
  margin-bottom: 20px;
}

.users p {
  margin-bottom: 20px;
}

.search-input {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ddd;
}

.users-table th, .users-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.users-table th {
  background-color: #a12367;
  color: white;
}

.users-table td {
  background-color: #ecf0f1;
}

.users-table button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  margin-right: 10px;
}

.users-table button:hover {
  color: #2980b9;
}

.users-table input[type="checkbox"] {
  transform: scale(1.5); /* Adjust scale to make checkbox bigger */
  margin-right: 10px;
}

@media (max-width: 768px) {
  .users {
    margin-right: 0;
    padding: 10px;
    width: 100%;
  }

  .users-table th, .users-table td {
    padding: 5px;
    font-size: 0.9em;
  }

  .search-input {
    width: 100%;
  }
}

/* Tooltip styles */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 80%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
