.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	justify-self: stretch;
}



.spinner-container .spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-top: 4px solid #767676;	
	border-radius: 100%;
	width: 5vw;
	height: 5vw;
	max-width: 25px;
	max-height: 25px;
	animation: spin 0.8s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
