.selector {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	margin-bottom: 0px;
}

.selector .input-label {	
	display: inline-block;
	white-space: nowrap;
	width: fit-content;
	text-align: right;
	margin: 0px 10px;
	font-weight: bold;
	font-size: 16px; /* Ensures the same font size */

}

.selector-select-element {
	flex: 1;
	padding: 0.5rem;
	border-radius: 5px;
	border: 1px solid #ccc;
	font-size: 16px; /* Ensures the same font size */
	background-color: white;
	color: black;
	width: fit-content;
}

.selector-select-element:focus {
	outline: none;
	border-color: #007bff;
	box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

