.text-editor-menu-bar .text-editor-menu-bar-button {
	font-weight: bold;
	align-items: center;
	justify-content: center;
	background-color: var(--button-primary-color);
	color: white;
	border-radius: 4px;
	box-shadow: none;
	cursor: pointer;
	border: #0d0d0d solid 1px;
	/* transition: background-color 0.3s, transform 0.3s; */
	font-size: 16px; /* Smaller font size for buttons */
	padding: 0.25rem 0.5rem;
	margin: 1px;
	border: #525252 solid 1px;
}

.text-editor-menu-bar .text-editor-menu-bar-button:hover {
	margin: 1px;
	background-color: #a20067;
	transform: none;
}

.text-editor-menu-bar .text-editor-menu-bar-button:disabled {
	background-color: #a0a0a0;
	cursor: not-allowed;
}

.text-editor-menu-bar .syntax-button,
.text-editor-menu-bar .syntax-button:hover,
.text-editor-menu-bar .use-button {
	background-color: white;
	color: black;
}

.text-editor-menu-bar .is-active,
.text-editor-menu-bar .is-active:hover,
.text-editor-menu-bar .use-button:hover {
	background-color: #0d0d0d;
	color: #fff;
}

.menu-bar .use-button {
	background-color: white;
	color: black;
}

.menu-bar .syntax-button:disabled,
.menu-bar .syntax-button:disabled:hover,
.menu-bar .use-button:disabled,
.menu-bar .use-button:disabled:hover {
	opacity: 0.5;
	background-color: white;
	color: black;
}

.toolbar-button-tooltip-container {
	position: relative;
	display: inline-block;
	cursor: pointer;
	width: fit-content;
}

.toolbar-button-tooltip-container .tooltip-text {
	position: fixed;
	width: 100px;
	background-color: white;
	color: black;
	text-align: center;
	border-radius: 5px;
	border-color: #0d0d0d;
	border: solid 1px;
	font-size: 12px;
	padding: 5px 0;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
	pointer-events: none; /* Prevent tooltip from interfering with mouse events */
}

.toolbar-button-tooltip-container:hover .tooltip-text {
	opacity: 1;
}
