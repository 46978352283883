.detailed-post-container {
	display: flex;
	flex-direction: column;
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 0.5em;
	text-align: right;
	background-color: rgb(243, 243, 243);
	justify-content: space-between;
	align-self: stretch;
	flex: 1;
	cursor: pointer;
	transition: background-color 0.3s;
	margin-bottom: 1em;
	position: relative; /* Ensure absolute positioning works within this container */
}

.detailed-post-container:hover {
	background-color: #f0f0f0;
	transform: scale(1.01);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.detailed-post-container .post-preview {
	display: flex;
	flex-direction: row;
	justify-content: flex-end; /* Ensure content goes to the bottom */
	flex: 1;
}

.detailed-post-container .post-image {
	width: 100%;
	min-height: 200px;
	max-height: 200px;
	margin-bottom: 0.5em;
	object-fit: scale-down;
}

.detailed-post-container .post-title {
	font-size: 20px;
	margin: 0 0 0.5em 0;
	overflow-wrap: break-word;
}

.detailed-post-container .title-date-container {
	margin-top: 2px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: first baseline;
	margin-right: 10px;
}

.detailed-post-container .post-date {
	color: #888;
	font-size: 14px;
	margin: 0;
	align-self: flex-start;
}

.detailed-post-container .post-description {
	font-size: 16px;
	margin: 5px 0;
	text-wrap: break-word;
	overflow-wrap: break-word;
	text-align: right;
	margin-right: 10px;
	flex: 1; /* Allow description to take up remaining space */
}

.detailed-post-container .post-button {
	border: none;
	padding: 0.6em 1em;
	cursor: pointer;
	border-radius: 5px;
	background: #a04598;
	color: #fff;
	font-size: 11px;
	max-width: 40%;
	margin-top: auto;
	align-self: flex-end;
}

.detailed-post-container .post-button:hover {
	background: #653a76;
}

.detailed-post-container .post-pdf-button {
	background: transparent;
	border: none;
	padding: 0.5em 1em;
	cursor: pointer;
	border-radius: 5px;
	font-size: 14px;
	text-align: center;
	max-width: 20%;
	transition: background-color 0.3s;
	overflow: hidden;
	align-self: flex-end;
}

.detailed-post-container .post-pdf-button img {
	width: 24px;
	height: 24px;
}

.detailed-post-container .post-pdf-button:hover {
	background: transparent;
}

@media (max-width: 600px) {
	.detailed-post-container .post-image {
		max-width: 95%;
	}
}
