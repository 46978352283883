/* src/screens/DonatePage/DonatePage.css */

.donate-page {
  padding: 20px 20px 0 20px;
  text-align: center;
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.donate-page h1 {
  margin-bottom: 5px;
  font-size: 2.5em;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.donate-page p {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #666;
  font-family: 'Arial', sans-serif;
}

.info-container {
  margin-top: 45px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1200px;
  align-items: stretch;
  flex-direction: row;
}

.info-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 250px; 
}

.info-icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 2em;
  color: #634585;
}

.info-box h2 {
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 1.8em;
  color: #333;
}

.info-box h4 {
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #555;
}

.info-box p {
  margin: 10px 0;
  font-size: 1.1em;
  color: #555;
  word-wrap: break-word;
  flex-grow: 1; 
}

.info-box a {
  color: #7c689b;
  text-decoration: none;
}

.info-box a:hover {
  text-decoration: underline;
}

/* Responsive Styles */

@media (max-width: 1200px) {
  .info-container {
    flex-wrap: wrap;
  }

  .info-box {
    margin: 10px;
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .info-container {
    flex-direction: column;
    align-items: center;
  }

  .info-box {
    margin: 10px 0;
    width: 90%;
  }

  .donate-page h1 {
    font-size: 2em;
  }

  .donate-page p {
    font-size: 1em;
  }

  .info-box h2 {
    font-size: 1.5em;
  }

  .info-box h4 {
    font-size: 1em;
  }

  .info-box p {
    font-size: 1em;
  }

  .info-icon {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .donate-page {
    padding: 10px 10px 0 10px;
  }

  .info-box {
    margin: 10px 0;
    width: 100%;
  }

  .donate-page h1 {
    font-size: 1.5em;
  }

  .donate-page p {
    font-size: 0.9em;
  }

  .info-box h2 {
    font-size: 1.3em;
  }

  .info-box h4 {
    font-size: 0.9em;
  }

  .info-box p {
    font-size: 0.9em;
  }

  .info-icon {
    font-size: 1.3em;
  }
}
