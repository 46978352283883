.tiptap {
	background: white;
	border-top: 1px solid rgba(128, 128, 128, 0.559);
	min-height: 300px;
	background-color: #f0f0f0;
	color: #333;
	width: auto;
	text-align: right;
	padding: 0px 10px;
}

.tiptap .tiptap-custom-link{
	pointer-events: auto;
}

.tiptap-editor-container {
	border-radius:0;
}

.editor-container .disabled .tiptap{
	pointer-events: none;
	border: none;
	background-color: #ffffff;
	min-height: 0px;
	height: fit-content;
}

.tiptap:focus {
	outline: 1px solid black;
}

.tiptap > * + * {
	margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
	padding: 0 2rem;
	margin: 0 2rem;
}

.tiptap ul,
.tiptap ol {
	padding: 0 2rem;
	margin: 1.25rem 1rem 1.25rem 1rem;
}

.tiptap ul li p,
.tiptap ol li p {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
	line-height: 1;
}

.tiptap img {
	max-width: 100%;
	height: auto;
}

.tiptap blockquote {
	padding-left: 1rem;
	border-left: 2px solid #0d0d0d;
}

.tiptap hr {
	border: none;
	border-top: 2px solid #0d0d0d;
	margin: 2rem 0;
}

.editor-container {
	width: 100%;
	padding: 0;
	margin: 0;
	border: 0;
}
