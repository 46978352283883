/* Contact.css */

.contact-item {
  width: 100%;
  border-radius: 8px;
}

.update-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.update-button:hover {
  background-color: #2980b9;
}

.modal-contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-contact-content {
  overflow-y: auto;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
}

.modal-contact-content h2 {
  margin-top: 0;
}

.contact-table {
  width: 100%;
  border-collapse: collapse;
}

.contact-table td {
  padding: 10px;
}

.contact-table input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.delete-icon {
  cursor: pointer;
  color: red;
}

.new-field-contact {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.new-field-contact h3 {
  margin-bottom: 10px;
}

.wide-input-contact {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.add-field-button {
  text-align: center;
}

.add-field-button button {
  background-color: #2ecc71;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-field-button button:hover {
  background-color: #27ae60;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.modal-actions .save-changes {
  background-color: #28a74b;
  color: white;
}

.modal-actions .cancel-changes {
  background-color: #555;
  color: white;
}

.modal-actions button:hover {
  opacity: 0.8;
}