/* src/components/Statistics/Statistics.css */
.stat-item {
  width: 100%;
  border-radius: 8px;
}

.stat-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.stat-field p {
  margin: 0;
}

.update-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.update-button:hover {
  background-color: #2980b9;
}

.modal-stat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-stat-content {
  overflow-y: auto;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
}

.modal-stat-content h2 {
  margin-top: 0;
}

.modal-stat-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-stat-field .delete-icon {
  order:2;
  align-self: flex-end;
}

.modal-stat-field label {
  display: flex;
  align-items: center;


}

.modal-stat-field input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: fit-content;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.modal-actions button:hover {
  background-color: #2980b9;
}

.modal-actions button:nth-child(2) {
  background-color: #ccc;
}

.modal-actions button:nth-child(2):hover {
  background-color: #aaa;
}

.delete-icon {
  cursor: pointer;
  color: red;
  margin-left: 10px;
}

.new-field-stat {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.new-field-stat h3 {
  margin-bottom: 10px;
}

.new-field-stat-inputs {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.new-field-stat input.wide-input-stat{
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.add-field-button {
  text-align: center;
}

.add-field-button button {
  background-color: #2ecc71;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-field-button button:hover {
  background-color: #27ae60;
}


.stat-table {
  /* right to left table */
  direction: rtl;
}