.element-editors-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0px;
	margin-bottom: 5px;
	padding: 0px;
	border-radius: 1rem;
	/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
	width: 100%;
}

.element-editors-container .add-element-button {
	background-color: var(--button-primary-color);
	font-size: 1.2em;
	color: white;
	margin: 15px;
}

.element-editors-container .add-element-button:hover {
	background-color: #a12367;
}
