.general-modal-dialog-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.general-modal-dialog {
	background: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	max-width: 600px;
	width: 90%;
	margin: auto;
	overflow: hidden;
	text-align: center; /* Center text content */
}

.general-modal-dialog h2 {
	margin-top: 0;
}

.general-modal-dialog form {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center form elements vertically */
}
.general-modal-dialog form .modal-field {
	width: 100%; /* Ensure full width for form fields */
	text-align: center;
}

.general-modal-dialog form label {
	display: block;
	text-align: center; /* Center label text */
	margin-bottom: 5px;
	width: 100%; /* Ensure full width for labels */
}

.general-modal-dialog form textarea {
	width: 100%; /* Full width */
	height: 150px; /* Increase height */
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	margin-top: 5px;
	box-sizing: border-box; /* Ensure padding is included in width */
}

.general-modal-actions {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
	width: 100%; /* Ensure full width for actions */
}

.general-modal-actions button {
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.general-modal-actions button:hover {
	background-color: #0056b3;
}

.general-modal-actions button:nth-child(2) {
	background-color: #ccc;
}

.general-modal-actions button:nth-child(2):hover {
	background-color: #aaa;
}

.general-modal-actions .confirm-button {
	background-color: #4caf50;
	color: white;
}

.general-modal-actions .confirm-button:hover {
	background-color: #3e8e41;
}

.general-modal-actions .confirm-button.warning-button {
	background-color: #dc3545;
	color: white;
}

.general-modal-actions .confirm-button.warning-button:hover {
	background-color: #c82333;
}

.general-modal-actions .cancel-button.warning-button {
	background-color: #4caf50;
	color: white;
}

.general-modal-actions .cancel-button.warning-button:hover {
	background-color: #3e8e41;
}