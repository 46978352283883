.text-area-with-limit-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 auto;
  height: fit-content;
}

.text-area-with-limit-container .text-area-with-limit {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  width: auto;
}

.text-area-with-limit-container .char-count {
  text-align: right;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}