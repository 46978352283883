.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #a04598;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.sidebar-header {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.sidebar-header h2 {
  margin-right: 10px;
  padding-bottom: 0;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  display: none; /* Hidden by default */
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar-menu li {
  width: 100%;
}

.sidebar-menu li a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 15px 20px;
  transition: background 0.3s, color 0.3s;
}

.sidebar-menu li a:hover {
  background: #34495e;
}

.sidebar-icon {
  margin-left: 10px;
  font-size: 1.2em;
}

.sidebar-menu span {
  flex: 1;
  text-align: right;
  font-size: 1em;
}

.hamburger-btn {
  background: none;
  border: none;
  color: black;
  font-size: 0.8em;
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  display: none; /* Hidden by default */
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 100%;
    transform: translateX(100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-header {
    justify-content: space-between;
  }

  .close-btn {
    display: block; /* Visible only on mobile */
  }

  .sidebar-menu {
    flex-direction: column;
    width: 100%;
  }

  .sidebar-menu li {
    width: 100%;
  }

  .sidebar-menu li a {
    padding: 20px;
    font-size: 1.2em;
  }

  .sidebar-icon {
    margin-left: 0;
    margin-right: 10px;
  }

  .hamburger-btn {
    display: block; /* Visible only on mobile */
  }
}
