.history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 1200px;
}

.history-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.history-entry {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  background-color: white;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.history-entry h2 {
  margin-top: 0;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
}

.history-entry .total-score {
  font-size: 1.5em;
  margin-bottom: 15px;
  text-align: center;
}

.toggle-responses-icon {
  font-size: 1.5em;
  color: #cc74a4;
  cursor: pointer;
  position: relative;
}

.toggle-responses-icon:hover {
  color: #ac1c74;
}

.responses-modal {
  background: white;
  border-radius: 10px;
  max-width: 800px;
  width: 80%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}

.responses-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.responses-modal h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.response-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
}

.response-item {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: right;
}

.close-modal-button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.filter-container {
  justify-content: center;
  gap: 10px;
}

.filter-container label {
  /*justify the text to the center of the input*/
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.close-modal-button:hover {
  background-color: #0056b3;
}

.history-entry .delete-icon {
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.error-no-history {
  color: #790000;
  margin-top: 10px;
}

.no-history {
  font-size: 1.5em;
  color: #888;
  margin-top: 20px;
}

.fill-questionnaire-button {
  background: #a04598;
  color: white;
  border: none;
  padding: 0.6em 1em;
  cursor: pointer;
  border-radius: 10px;
}

.fill-questionnaire-button:hover {
  background: #ac1c74;
}

.graph-container {
  width: 100%;
  margin-top: 40px;
  padding-bottom: 80px;
  margin: 0 auto;
}

.graph-background {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 400px; 
}

@media (max-width: 600px) {

  .graph-container {
    width: 90%;
    margin-top: 40px;
    padding-bottom: 80px;
    padding-left: 80px;
    margin: 0 auto;
  }
  
  .graph-background {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 400px; 
  }

  .graph-container {
    padding-bottom: 20px;
  }

  .history-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
  }

  .history-entry {
    width: 80%;
    max-width: 350px;
    margin-left: 35px;
  }

  .history-entry h2 {
    font-size: 1.4em; 
  }

  .history-entry .total-score {
    font-size: 1.2em; 
  }

  .toggle-responses-icon {
    font-size: 1.2em; 
  }

  .close-modal-button {
    font-size: 0.9em; 
  }

  .filter-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .history-container h1 {
    margin-left: 20px;
  }

  .filter-container {
    padding-left: 40px;
  }
}
