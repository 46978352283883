.pdf-div {
	background-color: #dedede;
	margin-top: 0px;
	width: 100%;
}

.pdf-div .pdf-document {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	margin: 1rem;
	margin-top: 0px;
}

.pdf-document .pdf-page {
	border-radius: 10px;
	width: 100%;
	height: fit-content;
	justify-content: stretch;
}

.pdf-page .react-pdf__Page__canvas {
	width: 100% !important;
	height: fit-content !important;
}

@media (max-width: 768px) {
	.pdf-div,
	.pdf-div .pdf-document,
	.pdf-document .pdf-page {
		padding: 0px;
		margin: 0px;
	}
	.react-pdf__Page__canvas {
		width: 100% !important;
		height: 100% !important;
	}
}
