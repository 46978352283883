.resizable-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #040303;
	border-radius: 4px;
}

.resizable-container .media-container {
	width: fit-content;
	height: fit-content;
	overflow: hidden;
}

.resizable-container .media-container img,
.resizable-container .media-container video,
.resizable-container .media-container audio {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

.resizable-container .react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #f0f0f09b;
	border-top: 1px solid #040303;
	border-left: 1px solid #040303;
}




  