.articles-posts-screen {
	background-color: #ffffff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	padding: 20px;
}

.articles-posts-screen h1 {
	margin-bottom: 10px;

}

@media screen and (max-width: 768px) {
	.articles-posts-screen {
		padding: 5vw;
	}
}