/* src/components/AnalyticsSection/AnalyticsSection.css */

.analytics-section {
  padding: 20px;
  background-color: #ffffff;
}

.analytics-text {
  margin-bottom: 20px;
  text-align: center; /* Center the text */
}

.analytics-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center items horizontally */
}

.analytics-number {
  background-color: #f5f5f5; /* Light grey */
  border-radius: 50%; /* Make it a circle */
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.analytics-number h3 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #333;
}

.countup-number {
  font-size: 48px;
  font-weight: bold;
  color:#a20067;

  animation: grow 1s ease-in-out;
  margin-bottom: 10px; /* Add margin to separate from text */
}

@keyframes grow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
