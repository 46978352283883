.admin-explanation-container::before {
  content: '';
  background-color: #f5f5f5;
}
.admin-explanation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the height calculation */
  /* margin-right: 250px; */
}

.admin-explanation {
  text-align: center;
  background-color: #ffffff;
  padding: 40px; /* Increased padding for a bigger box */
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
  width: 90vw; /* Adjusted width */
}

.welcome-header {
  font-size: 2em; /* Increased font size */
  color: #2c3e50;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 1.0em; /* Increased font size */
  color: #34495e;
  margin-bottom: 20px;
}

/* Container for the PDF icon and title */
.pdf-container-explain {
  /* left corner */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pdf-icon-explain {
  width: 30px;
  height: 30px;
  cursor: pointer;
  flex-direction: column;
}

.pdf-title-explain{
  font-size: 1em;
  color: #34495e;
}


.category-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.0em; /* Increased font size */
  text-indent: 10px; /* Indent text for better readability */
}

.available-text{
  font-size: 1.0em;
  color: #ac1c74;
}

.category-item {
  margin-bottom: 20px; /* Increased margin for better spacing */
  padding: 20px; /* Increased padding for a bigger box */
  background-color: #ecf0f1;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Needed for the purple strip */
  text-align: right; /* Align text to the right */
}

.category-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: #724086; /* Purple color */
  border-top-left-radius: 5px; /* Match the border-radius of the category item */
  border-bottom-left-radius: 5px; /* Match the border-radius of the category item */
}

.category-item strong {
  color:#724086
; /* Change to dark blue */
  margin-left: 15px; /* Add padding to avoid overlap with the strip */
}

/* Media Queries */
@media (max-width: 1200px) {
  .admin-explanation-container::before
  {
    display: none;
  }
  .welcome-header {
    font-size: 2.5em;
  }
  
  .intro-text {
    font-size: 1.3em;
  }
  
  .category-list {
    font-size: 1.2em;
  }
}

@media (max-width: 992px) {
  .admin-explanation {
    padding: 30px;
    max-width: 800px;
  }
  
  .welcome-header {
    font-size: 2em;
  }
  
  .intro-text {
    font-size: 1.2em;
  }
  
  .category-list {
    font-size: 1.1em;
  }
  
  .category-item {
    padding: 15px;
  }
  
  .category-item::before {
    width: 8px;
  }
}

@media (max-width: 768px) {
  .admin-explanation {
    padding: 20px;
    max-width: 600px;
  }
  
  .welcome-header {
    font-size: 1.8em;
  }
  
  .intro-text {
    font-size: 1.1em;
  }
  
  .category-list {
    font-size: 1em;
  }
  
  .category-item {
    padding: 10px;
  }
  
  .category-item::before {
    width: 6px;
  }
}

@media (max-width: 576px) {
  .admin-explanation {
    padding: 15px;
    max-width: 400px;
  }
  
  .welcome-header {
    font-size: 1.5em;
  }
  
  .intro-text {
    font-size: 1em;
  }
  
  .category-list {
    font-size: 0.9em;
  }
  
  .category-item {
    padding: 8px;
  }
  
  .category-item::before {
    width: 5px;
  }
}
