.text-editor-menu-bar {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin: 0px;
	padding-bottom: 5px;
	padding-top: 5px;
	/*here*/
	background-color: #f3f3f3;
	border-radius: 0;
	gap: 2px;

}


.text-editor-menu-bar .sub-menu-bar {
	 float: right;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 0px;
	padding: 0px;
}

.text-editor-menu-bar .sub-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 0px;
	padding: 0px 5px;
}

/* from about square */
@media (max-width: 1114px) {
	.text-editor-menu-bar{
		flex-direction: column;
		justify-content: flex-start;
	}
}