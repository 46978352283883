/* src/screens/Homepage/Homepage.css */
.header {
	width: 100vw;
	position: relative;
	text-align: center;
	color: #333333ba;
}

.header-image-container {
	width: 100%;
	height: 38vh; /* Set a fixed height to prevent layout shifts */
	overflow: hidden;
	position: relative;
	text-align: center;
}

.image-placeholder {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #aaa;
}

.header-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 100% 70%;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.header-image.loaded {
	opacity: 1;
}

.header-text {
	position: absolute;
	top: 50%;
	right: 2%;
	transform: translateY(-50%);
	text-align: right;
}

.header-text h1 {
	font-size: 2.5em;
	margin: 0;
	background: linear-gradient(90deg, #cc74a4, #9326cc, #7330b7, #d14699, #d484ac, #6c4a8c);
	background-clip: text;
	color: transparent;
	-webkit-background-clip: text;
	-webkit-text-stroke-width: 1px;
}

.header-text p {
	font-size: 1.2em;
	margin: 0;
}

.fill-form-button {
	margin-top: 1em;
	margin-right: 1em;
	background-color: #874f97;
	color: #fff;
	border: none;
	padding: 1em 2em;
	cursor: pointer;
	border-radius: 5px;
	font-size: 1em;
	font-weight: 500;
}

.fill-form-button:hover {
	background-color: #a20067;
	box-shadow: 0 0 15px #f5f5f5;
}

.donate-button {
	background: #a04598;
	color: #fff;
	border: none;
	padding: 1em 2em;
	cursor: pointer;
	border-radius: 5px;
	font-size: 1em;
}

.donate-button:hover {
	background: #b42c7c;
}

section {
	margin-bottom: 2em;
	background: white;
	padding: 2em;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

section h2 {
	font-size: 2em;
	margin-top: 0;
}

.service {
	border: 1px solid #ddd;
	padding: 1em;
	margin-bottom: 1em;
	border-radius: 8px;
	background: #fafafa;
}

nav {
	background: #333;
	color: white;
	padding: 1em;
}

nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: flex-end;
	font-weight: 500;
}

nav ul li {
	margin-left: 1em;
}

nav ul li a {
	color: white;
	text-decoration: none;
	font-size: 1em;
}

nav ul li a:hover {
	text-decoration: underline;
}

.homepage {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.fade-in {
	opacity: 0;
	animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
	to {
	  opacity: 1;
	}
  }

@media (max-width: 768px) {
	section {
		margin-bottom: 4vw;
		padding: 4vw;
	}

	.header-image-container {
		height: 24vh;
		min-height: 175px;
	}

	.header-text {
		margin-top: 3.3em;
		right: 0;
		padding: 0 1em;
	}

	.header-text h1 {
		display: none;
		font-size: 2em;
		text-align: right;
	}

	.header-text h3 {
		display: none;
	}

	.header-text p {
		font-size: 1em;
	}

	.header-image {
		height: 20vh;
		min-height: 140px;
	}

	.fill-form-button {
		background-color: #a04598;
	}

	.fill-form-button:hover {
		background: #b42c7c;
	}
}
