.donation-section {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    background: #ffffff;
  }
  
  .donation-text {
    flex: 1;
    text-align: right;
    padding-right: 2em;
  }
  
  .donation-text h2 {
    align-self: right;
    text-align: right;
    font-size: 2em;
  }
  
  .donation-text p {
    font-size: 1.2em;
  }
  
  .donate-button {
    background: #a04598;
    color: #fff;
    border: none;
    padding: 1em 2em;
    cursor: pointer;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  
  .donate-button:hover {
    background: #b42c7c;
  }
  
  .donation-image {
    flex: 1;
    max-width: 40%;
    /* border-radius: 20px; */
    /* align more to the left */
    margin-left: -10%;
  }
  

@media screen and (max-width: 768px) {
    .donation-text {
      padding-right: 5vw;
    }
}