:root {
	--button-primary-color: #a04598;
	--button-primary-color-hover: #765092;
	--button-primary-color-disabled: #3c3c3c;
	--admin-sidebar-width: 250px;
	--padding-general: 35px;

	--background-color-general: #f5f5f5;
}

@media screen and (max-width: 768px) {
	:root {
		--padding-general: 5vw;
	}
}

html,
body,
#root {
	display: block;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
	direction: rtl; /* Right-to-Left */
	text-align: right; /* Align text to the right */
	background-color: var(--background-color-general);
	color: #333;
	overflow: visible;
	/* min-height: 500px; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	background: var(--button-primary-color);
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 10px;
	font-size: 16px;
	transition: background-color 0.3s;
}

button:hover {
	background: var(--button-primary-color-hover);
	cursor: pointer;
	color: #fff;
}

button:disabled {
	cursor: not-allowed;
}

.main-flex-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.main-flex-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.general-container {
	padding: var(--padding-general)
}
