.resource-input-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	margin-top: -5px;
	background: #fff;
	border-radius: 8px;
	width: 100%;
}

.resource-input-container .title {
	text-align: right;
	font-size: 16px;
	margin-left: 10px;
}

.resource-input-container h3 {
	color: #333;
	margin-bottom: 1em;
}


.resource-input-container .file-input,
.resource-input-container .file-info-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0px 10px;
}

.resource-input-container .file-input {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.resource-input-container .file-input input[type='file'] {
	margin-right: 10px; /* Add some space between the input and the button */
}

.resource-input-container .file-info-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.file-info-container .download-button {
	background-color: #555;
	color: white;
}

.file-info-container .download-button:hover {
	background-color: #333;
}


.resource-input-container .file-info-container h4 {
	margin-right: 10px; /* Add space between the text and the link */
}

.resource-input-container .file-info-container a {
	color: #007bff;
	text-decoration: underline;
	margin-right: 10px; /* Add space between the link and the button */
}

.resource-input-container .file-info-container a:hover {
	text-shadow: 0 0 1px #007bff;
}


.resource-input-container .file-info-container button {
	border: none;
	padding: 7px 15px;
	border-radius: 5px;
	cursor: pointer;
	margin: 0px 10px;
	transition: background-color 0.3s, transform 0.3s;
}
.resource-input-container .file-info-container button {
	transform: translateY(-2px);
}

.resource-input-container .delete-button-input {
	background-color: #d44242;
	color: white;
}

.resource-input-container .delete-button-input:hover {
	background-color: #9b2323;
}

.resource-input-container .download-button-input {
	background-color: #007bff;
	color: white;
}

.resource-input-container .status-message {
	color: #555;
	margin-top: 10px;
}

.resource-input-container .status-message.error {
	color: #d44242;
}
.resource-input-container .file-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
}

.resource-input-container .status-message-container {
	display: flex;
	flex-direction: row;
	align-items: right;
	justify-items: center;
	margin-top: 10px;
}
