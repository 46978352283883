

/* Removed .navbar styles */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.login-form {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form h2 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}

.login-form label {
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: bold;
  align-items: center;
  color: #555;
}

.login-form input {
  display: inline-block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.login-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #a04598;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px;
}

.login-form button:hover {
  background-color: #555;
}

.login-form p {
  color: red;
  margin-top: 1rem;
  text-align: center;
}

.modal-login {
  position: absolute;
  top: 50%;
  left: 60%;
  right: auto;
  bottom: auto; 
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 350px; /* Anchura por defecto */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 95%; /* Asegura que no se salga de los límites en pantallas pequeñas */
}

.modal-login h2 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #333;
  text-align: center;
}

.modal-login label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  align-items: center;
  color: #555;
}

.modal-login input {  
  width: calc(100% - 1.5rem); /* Ajusta el ancho restando el padding */
  padding: 0.75rem;
  margin: 0 auto; /* Añade margin auto para centrar horizontalmente */
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.modal-login button {
  width: 100%;
  padding: 0.75rem;
  background-color: #a04598;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.modal-login button:hover {
  background-color: #555;
}

@media (max-width: 432px) {
  .modal-login {
    width: 90%; /* Aumenta el ancho para pantallas muy pequeñas */
    padding: 1rem; /* Reduce el padding para más espacio */
    transform: translate(-50%, -40%); /* Ajusta la posición para que esté más visible y centrado */
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}
